.OuterDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PhantomDiv {
  font: 1.3rem bold black;
  margin: 10px 3rem;
  text-align: left;
}
p {
  margin: 0;
  font-weight: 600;
}
.BlockButton {
  display: block;
  width: 100%;
  border: none;
  cursor: pointer;
  text-align: left;
}
.table-flex {
  height: 30rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
