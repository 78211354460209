.loginContainer {
    min-height: calc(100vh - 1px);
    background-color: #cdcdcd;
    background-image: linear-gradient(to bottom right, #fff, #eac4a3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoginForm {
    padding: 3rem;
    background-color: #fff;
    box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
    max-width: 440px;
    margin: auto;
}