.closedSidebar {
    width: 58px !important;
}

.sidebarCommonStyle {
    min-height: calc(100vh - 65px);
    flex-shrink: 0;
    width: 248px;
    background-color: #fff;
    border-right: 1px solid #cdcdcd;
    /* overflow-x: hidden; */
    transition: all .3s ease-in-out;
    position: relative;
    /* background-color: #f8f8f8; */
    /* border-radius: 12px; */
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes grow
{
  0% , 40% , 100%
  {
    transform:scale(0.9);
  }
  20%{
     transform:scale(1);

  }
}

.copy-block button svg{
  height: 0.7rem !important;
  width: 0.7rem !important;
}